<template>
  <div id="resource">
    <div class="conent">
      <div class="header">
        <h1>快速拼图</h1>
      </div>
      <Table :loading="loading"
             :columns="columns"
             :data="modelData"
             style="width:100%"></Table>
      <div class="Page">
        <Page :total="total"
              show-total
              :current="parseInt(page)"
              @on-change="changePage" />
      </div>
    </div>
    <!--弹框区-->
    <!--快速拼图显示-->
    <Modal v-model="MapShou"
           class-name="addModel-modal"
           fullscreen
           footer-hide
           @on-cancel="MapCancel">
      <p slot="header">
        <span>{{ mapName }}</span>
      </p>
      <div class="ContentBox">
        <div class="mapContent"
             id="mapContent"
             v-show="mapType"
             style="width: 100%; height: 100%"></div>
        <div class="map3DContent"
             id="map3DContent"
             v-show="!mapType"
             style="width: 100%; height: 100%"></div>
        <div class="switch"
             :style="!mapType ? 'opacity: 1' : 'opacity: 0.6'"
             @click="mapType = !mapType">
          <img :src="switch3D"
               alt="" />
        </div>
      </div>
    </Modal>
    <!--消防快速拼图对比-->
    <Modal v-model="MapShou_2"
           class-name="MapShou-modal"
           fullscreen
           footer-hide
           @on-cancel="MapCancel_2">
      <p slot="header">
        <span>{{ mapName }}</span>
      </p>
      <div class="ContentBox">
        <div class="top">
          <h1 v-if="!edit"
              @dblclick="editMsg">{{ correlationData.name }}</h1>
          <Input v-if="edit"
                 v-model="correlationData.name"
                 @on-enter="editMsgEnd"
                 @on-blur="editMsgEnd"
                 style="width: 600px" />
        </div>
        <div class="Content">
          <div class="left">
            <div class="box">
              <div class="map previous"
                   id="previous"></div>
              <h2>灾前卫星图</h2>
              <img :src="compass"
                   alt="" />
            </div>
            <div class="text">
              <h4 v-if="!edit"
                  @dblclick="editMsg">
                制图单位 : {{ correlationData.chartingName }}
              </h4>
              <Input v-if="edit"
                     v-model="correlationData.chartingName"
                     @on-enter="editMsgEnd"
                     @on-blur="editMsgEnd"
                     style="width: 600px" />
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div class="map upToDate"
                   id="upToDate"></div>
              <h3>灾后正射图</h3>
              <img :src="compass"
                   alt="" />
            </div>
            <div class="text">
              <h4>制图时间 : {{ correlationData.date }}</h4>
              <h4>
                <span>lng : {{ correlationData.lng }} ， lat :
                  {{ correlationData.lat }}</span>
              </h4>
            </div>
          </div>
          <div class="editBox">
            <div>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import switch3D from '@/assets/img/Icon/Switch3dMap.png';
import compass from '@/assets/img/Icon/compass.png';
import Api from '@/utils/api.js';
export default {
  name: 'index',
  data() {
    return {
      teamID: -1,
      userType: '',
      userInfo: {},
      Project1info: {},
      columns: [
        {
          title: '地图名称',
          key: 'mapname',
          align: 'center',
        },
        {
          title: '拍摄日期',
          key: 'shooting_date',
          align: 'center',
        },
        {
          title: '区域',
          key: 'address',
          align: 'center',
        },
        {
          title: '查看',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  shape: 'circle',
                  icon: 'ios-search',
                },
                style: {
                  margin: '0 5px',
                },
                attrs: {
                  title: '查看',
                },
                on: {
                  click: () => {
                    console.log('查看行', params);
                    this.MapShou = true;
                    this.mapName = params.row.mapname;
                    this.toLoadMapImg(params.row);
                  },
                },
              }),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  shape: 'circle',
                  icon: 'md-image',
                },
                style: {
                  margin: '0 5px',
                  display: this.userInfo.pm_id == 68 || 190 ? 'blok' : 'none',
                },
                attrs: {
                  title: '增加标注',
                },
                on: {
                  click: () => {
                    sessionStorage.setItem(
                      'contrast',
                      JSON.stringify(params.row)
                    );
                    this.$router.push({
                      //   name: "快速对比",
                      path: '/rapidMap/label',
                    });
                    // this.MapShou_2 = true;
                    // this.toLoadMapImg_2(params.row);
                  },
                },
              }),
            ]);
          },
        },
        // {
        //     title: '操作',
        //     align: 'center',
        //     render: (h, params) => {
        //         return h('div', [
        //             h('Button', {
        //                 props: {
        //                     type: 'primary',
        //                     size: 'small'
        //                 },
        //                 style:{
        //                     marginRight:"10px"
        //                 },
        //                 on: {
        //                     click: () => {
        //
        //                     }
        //                 }
        //             }, '编辑'),
        //             h('Button', {
        //                 props: {
        //                     type: 'error',
        //                     size: 'small'
        //                 },
        //                 on: {
        //                     click: () => {
        //
        //                     }
        //                 }
        //             }, '删除')
        //         ]);
        //     }
        // }
      ],
      switch3D,
      compass,
      loading: false,
      modelData: [],
      MapData: null,
      MapList: null,
      total: 0,
      current: 1,
      mapName: '',
      MapShou: false,
      mapType: true, //true=高德地图 false=Cesium地图
      map: null,
      imageLayer: null,
      materialImg: null,
      //消防
      MapShou_2: false,
      correlationData: {
        name: '暂无标题',
        date: '0000-00-00',
        chartingName: '暂无制图单位',
        lng: '0',
        lat: '0',
        img: '',
      },
      edit: false,
      map_2: null,
      map_2_upToDate: null,
      imageLayer_2: null,
      page: 1,
      showPage: false,
      viewer: '', // cesium实例
    };
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType');
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page');
    }
    // this.teamID = this.userInfo.team_id;
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'));
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = -1;
      this.getMapList();
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
      this.getMapList();
    }
    this.init_map();
    // this.init_map_2();
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value;
      this.changePage(1);
    });
  },
  methods: {
    // 获取快速拼图列表
    getMapList() {
      this.loading = true;
      this.showPage = false;
      this.$post(Api.getPuzzleList(), {
        team_id: this.teamID,
        page: this.page,
        limit: 10,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.MapData = res.data;
          this.modelData = res.data.rows;
          console.log('获取详情-结果', this.modelData);
          this.total = res.data.total;
          if (this.total > 10) {
            setTimeout(() => {
              this.showPage = true;
            }, 100);
          }
          this.current = res.data.current_page;
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    changePage(ind) {
      this.page = ind;
      sessionStorage.setItem('page', ind);
      this.getMapList();
    },
    // 创建地图
    init_map() {
      let that = this;
      let layer = [];
      //高德地图区------------------------------------------
      // 引入瓦片图-平面
      if (this.$online) {
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }

      this.map = new AMap.Map('mapContent', {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 20],
        center: this.$Center,
        layers: layer,
      });
      // 修改主题样式
      // this.map.setMapStyle("amap://styles/whitesmoke");

      // Cesium区域------------------------------------------
      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken();
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      //   Cesium.Camera.DEFAULT_VIEW_RECTANGLE() = Cesium.Rectangle.fromDegrees(
      //     73.0,
      //     3.0,
      //     135.0,
      //     53.0
      //   );
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        73.0,
        3.0,
        135.0,
        53.0
      );

      that.viewer = new Cesium.Viewer('map3DContent', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        // 加载Cesium自带地图
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        // }),
      });
      that.viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以
      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      //   window.Viewer_rapidMap = that.viewer;
      // 去掉版权信息
      that.viewer.cesiumWidget.creditContainer.style.display = 'none';

      //天地图卫星影像
      // 加载本地地图=======================================
      //   var arcgis_blue = that.viewer.imageryLayers.addImageryProvider(
      //     new Cesium.TileMapServiceImageryProvider({
      //       // 有的版本是用createTileMapServiceImageryProvider
      //       url: Api.TianDiTuMap(),
      //       fileExtension: 'png',
      //     })
      //   );

      // 开启地形深度检测
      // that.viewer.scene.globe.depthTestAgainstTerrain = true;

      // home定位到具体地点和高度(长沙)
      that.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true;
          //你要飞的位置
          that.viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              //   119.286808,
              //   26.053644,
              that.$Center[0],
              that.$Center[1],
              50000
            ),
          });
        }
      );
    },
    // 加载快速拼图
    toLoadMapImg(row) {
      let location = '';
      if (row.angle_data) {
        location = JSON.parse(row.angle_data);
      }
      this.map.clearMap();
      //高德地图区========================================
      if (location) {
        console.log('快速拼图详情');
        this.imageLayer = null;
        // this.imageLayer = new AMap.ImageLayer({
        //   //   bounds: new AMap.Bounds(
        //   //     [location.leftdown.lng, location.leftdown.lat],
        //   //     [location.rightup.lng, location.rightup.lat]
        //   //   ),
        //   url: row.path, // 图片 Url
        //   zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
        //   zIndex: 12,
        // });

        // 将图层添加至地图实例
        // this.map.add(this.imageLayer);
        // this.map.setCenter([location.leftdown.lng, location.leftdown.lat]);

        //Cesium地图区========================================
        let viewer = this.viewer;

        // 高德坐标转WGS84
        var A = gcj02towgs84(location.leftdown.lng, location.leftdown.lat);
        var B = gcj02towgs84(location.rightup.lng, location.leftdown.lat);
        var C = gcj02towgs84(location.rightup.lng, location.rightup.lat);
        var D = gcj02towgs84(location.leftdown.lng, location.rightup.lat);

        this.materialImg = null;
        this.materialImg = viewer.entities.add({
          polygon: {
            hierarchy: new Cesium.PolygonHierarchy(
              Cesium.Cartesian3.fromDegreesArray([
                A[0],
                A[1],
                B[0],
                B[1],
                C[0],
                C[1],
                D[0],
                D[1],
              ])
            ),
            classificationType: Cesium.ClassificationType.BOTH,
            material: row.path,
          },
        });
        viewer.zoomTo(this.materialImg);
      }
    },
    //关闭弹窗
    MapCancel() {
      this.imageLayer && this.map.remove(this.imageLayer);
      //   let viewer = window.Viewer_rapidMap;
      this.materialImg && this.viewer.entities.remove(this.materialImg);
      this.mapType = true;
    },

    //消防对比
    // 加载消防对比
    // init_map_2() {
    //   var _this = this;
    //   // 引入瓦片图-平面

    //   this.map_2 = new AMap.Map('previous', {
    //     resizeEnable: true,
    //     expandZoomRange: true,
    //     zoom: 18, //地图显示的缩放级别
    //     center: [112.97935279, 28.21347823],
    //     zooms: [3, 20],
    //     // layers: [new AMap.TileLayer.Satellite()],
    //     layers: [
    //       new AMap.TileLayer({
    //         getTileUrl: function (x, y, z) {
    //           return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
    //         },
    //         zIndex: 10,
    //       }),
    //     ],
    //   });
    //   // 修改主题样式
    //   // this.map_2.setMapStyle("amap://styles/whitesmoke");
    //   this.map_2.addControl(new AMap.Scale());

    //   this.map_2_upToDate = new AMap.Map('upToDate', {
    //     resizeEnable: true,
    //     expandZoomRange: true,
    //     zoom: 18, //地图显示的缩放级别
    //     zooms: [3, 20],
    //     center: [112.97935279, 28.21347823],
    //     layers: [
    //       new AMap.TileLayer({
    //         getTileUrl: function (x, y, z) {
    //           return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
    //         },
    //       }),
    //     ],
    //     // layers: [new AMap.TileLayer.Satellite()],
    //   });

    //   // 修改主题样式
    //   // this.map_2_upToDate.setMapStyle("amap://styles/whitesmoke");
    //   //添加比例尺
    //   this.map_2_upToDate.addControl(new AMap.Scale());
    //   this.map_2.addControl(new AMap.Scale());

    //   //同步操作
    //   //拖拽
    //   this.map_2.on('dragging', () => {
    //     var zoom = _this.map_2.getZoom();
    //     var location = _this.map_2.getCenter();
    //     _this.map_2_upToDate.setZoomAndCenter(zoom, [
    //       location.lng,
    //       location.lat,
    //     ]); //同时设置地图层级与中心点
    //   });
    //   this.map_2_upToDate.on('dragging', () => {
    //     var zoom = _this.map_2_upToDate.getZoom();
    //     var location = _this.map_2_upToDate.getCenter();
    //     _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
    //   });
    //   //放大缩小
    //   this.map_2.on('zoomchange', () => {
    //     var zoom = _this.map_2.getZoom();
    //     var location = _this.map_2.getCenter();
    //     _this.map_2_upToDate.setZoomAndCenter(zoom, [
    //       location.lng,
    //       location.lat,
    //     ]); //同时设置地图层级与中心点
    //   });
    //   this.map_2_upToDate.on('zoomchange', () => {
    //     var zoom = _this.map_2_upToDate.getZoom();
    //     var location = _this.map_2_upToDate.getCenter();
    //     _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
    //   });
    // },
    // toLoadMapImg_2(row) {
    //   console.log(row);
    //   // 拿到数据
    //   this.correlationData.date = row.shooting_date;
    //   this.correlationData.img = row.path;
    //   var location = JSON.parse(row.four_corners);
    //   var lng =
    //     (Number(location.leftdown.lng) + Number(location.rightup.lng)) / 2;
    //   var lat =
    //     (Number(location.leftdown.lat) + Number(location.rightup.lat)) / 2;
    //   this.correlationData.lng = lng;
    //   this.correlationData.lat = lat;
    //   console.log(this.correlationData.lng);
    //   //在地图加载图片
    //   this.map_2.clearMap();
    //   this.map_2_upToDate.clearMap();
    //   if (location) {
    //     this.imageLayer_2 = null;
    //     this.imageLayer_2 = new AMap.ImageLayer({
    //       bounds: new AMap.Bounds(
    //         [location.leftdown.lng, location.leftdown.lat],
    //         [location.rightup.lng, location.rightup.lat]
    //       ),
    //       url: row.path, // 图片 Url
    //       zooms: [3, 19], // 设置可见级别，[最小级别，最大级别]
    //     });
    //     // 将图层添加至地图实例
    //     this.map_2_upToDate.add(this.imageLayer_2);
    //     this.map_2_upToDate.setCenter([lng, lat]);
    //     this.map_2.setCenter([lng, lat]);
    //   }
    // },
    // 开启编辑
    editMsg() {
      this.edit = true;
    },
    //关闭编辑
    editMsgEnd() {
      console.log(222);
      this.edit = false;
    },
    //关闭弹窗
    MapCancel_2() {
      this.map_2_upToDate.remove(this.imageLayer_2);
    },
  },

  beforeDestroy() {
    if (this.viewer != '') {
      this.viewer.destroy();
      this.viewer = '';
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
#resource {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 20px;
  background-color: #f8f8f9;
  .conent {
    width: calc(100% - 40px);
    min-width: 800px;
    margin: 24px 20px;
    padding: 24px 20px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 3px 6px #ddd;
    border-radius: 4px;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    .header {
      display: flex;
      align-items: center;
      h1 {
        font-size: 30px;
        margin-right: 30px;
      }
      margin-bottom: 20px;
    }

    .Page {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
/deep/.addModel-modal {
  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .action {
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.6;
      .img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      span {
        color: #666666;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .selected {
      opacity: 1;
    }
    .Separator {
      width: 1px;
      height: 120px;
      background-color: #d6d6d6;
    }
  }
}
/deep/.ivu-modal-body {
  padding: 0;
  .ContentBox {
    width: 100%;
    height: 100%;
    position: absolute;
    .mapContent {
      width: 100%;
      height: 100%;
    }
    .map3DContent {
      width: 100%;
      height: 100%;
    }
    .switch {
      width: pcRem(40);
      height: pcRem(40);
      border-radius: pcRem(10);
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.6;
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}
/deep/.MapShou-modal {
  .ContentBox {
    .top {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        cursor: pointer;
        display: block;
      }
    }
    .Content {
      width: 100%;
      height: 800px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 45%;
        margin-left: 40px;
      }
      .right {
        width: 45%;
      }
      .editBox {
        width: 50px;
        height: 650px;
        background-color: #f8f8f9;
      }
      .box {
        width: 100%;
        height: 650px;
        margin-bottom: 20px;
        position: relative;
        .map,
        .img {
          width: 100%;
          height: 100%;
          background-color: #e6e6e6;
        }
        h2 {
          position: absolute;
          bottom: 10px;
          right: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #3860ee;
          text-shadow: 1px 1px 0px #ffffff, -1px -1px 0px #ffffff,
            2px 2px 0px #ffffff, -2px -2px 0px #ffffff, 3px 3px 0px #ffffff,
            -3px -3px 0px #ffffff;
        }
        h3 {
          position: absolute;
          bottom: 10px;
          right: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #ff021a;
          text-shadow: 1px 1px 0px #ffffff, -1px -1px 0px #ffffff,
            2px 2px 0px #ffffff, -2px -2px 0px #ffffff, 3px 3px 0px #ffffff,
            -3px -3px 0px #ffffff;
        }
        img {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .text {
        width: 100%;
        height: 100px;
        /*padding: 10px 20px;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 24px;
          span {
            font-size: 20px;
          }
        }
      }
    }
    .amap-scale-text {
      color: #fff;
    }
  }
}
</style>
